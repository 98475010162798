import { Link } from "gatsby";
import React from "react"

import CiFooterStyle from "./ci-footer.module.scss";

const CiFooterFr = (props) => {

    return <section className={ CiFooterStyle.footer }>
        <div className={ CiFooterStyle.inner }> 

            <div className={ CiFooterStyle.left }>
                <a href="https://www.conversational-maturity-index.com/fr">
                    <img id={ CiFooterStyle.left } src="https://info.iadvize.com/hubfs/CI%20-%202021/Property%201=Primaire.png" alt=""/>
                </a>

                <Link to="/fr/legals">
                    <span>Mentions légales et CGU</span>
                </Link>

                <Link to="/fr/rgpd">
                    <span>RGPD</span>
                </Link>
                
            </div>

            
            <div className={ CiFooterStyle.right }>
                <span>powered by</span>

                <a className={ CiFooterStyle.linkIdz } href="https://www.iadvize.com/fr/">
                    <img src="https://cdn2.hubspot.net/hubfs/403167/WEBSITE-2020/HOMEPAGE/Logo%20iadvize%20(2).png" alt=""/>
                </a>

            </div>
        </div>
    </section>
  
}

export default CiFooterFr




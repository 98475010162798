import React from "react"

import { Link } from "gatsby";

import CiMethodoStyle from "./ci-methodo.module.scss";

const CiLegalFr = (props) => {

        return <section className={ CiMethodoStyle.methodo }>
            <div className={ CiMethodoStyle.inner }>
            <h3>Mentions légales et CGU</h3>
            <div className={ CiMethodoStyle.content }>
                <span>Mentions légales</span>
                <br/><br/>
                Dernière modification : 8 mars 2021
                <br/><br/>
                <strong>Société</strong><br/>
                iAdvize Société par actions simplifiée au capital de 39.536,42€<br/>
                RCS Nantes : 519 698 914<br/>
                n° de TVA intracommunautaire : FR40519698914<br/>
                Déclaration CNIL : 1453092<br/>
                <br/><br/>
                <strong>Adresse</strong><br/>
                iAdvize<br/>
                Euronantes Gare, Le Berlingot bât. B<br/>
                9 Rue Nina Simone, 44000 Nantes, France<br/>
                <br/><br/>
                <strong>Contact</strong><br/>
                contact@iadvize.com<br/>
                +33 (0)8 05 69 61 31<br/>
                <br/><br/>
                <strong>Hébergement</strong><br/>
                Netlify, Inc. - <a href="https://www.netlify.com/">Netlify</a><br/>
                2325 3rd Street, Suite 296, San Francisco, California 94107<br/>
                <br/><br/>
                Droit applicable : Les présentes mentions légales et CGU sont soumises au droit français. 
                Tout différend susceptible de survenir en lien avec l’utilisation ou le contenu du présent site sera soumis à la compétence non-exclusive des juridictions compétentes du siège social d’iAdvize.
                <br/><br/>
            </div>
            <hr></hr>
            <div className={ CiMethodoStyle.content }>
                <br/><br/>
                <span>Conditions Générales d’Utilisation</span>
                <br/><br/>
                Dernière modification : 8 mars 2021
                <br/><br/>
                En naviguant sur les sites www.iadvize.com, www.ibbu.com, www.conversational-maturity-index.com et autres sites ou mini-sites appartenant à iAdvize (référence est faite à chacun de ces sites individuels en tant que le « Site »), vous acceptez et vous vous engagez sans réserve à respecter les présentes Conditions Générales d’Utilisation dont nous vous demandons de prendre connaissance avec attention.
                L’internaute s’engage à ne pas procéder à des opérations techniques susceptibles de porter atteinte à l’intégrité, la sécurité ou la disponibilité du Site, et notamment s’engage à ne pas réaliser d’étude du code source ou d’ingénierie inversée.
                <br/><br/>
                <strong>Si vous n’acceptez pas les présentes Conditions Générales d’Utilisation, vous n’êtes pas autorisé à utiliser le présent Site et devrez immédiatement mettre fin à cette utilisation.</strong><br/><br/>
                <strong>Utilisation du contenu du Site – Droits de propriété intellectuelle</strong><br/>
                Le présent Site ainsi que tout le matériel et les informations publiés sur le Site, y compris, notamment, les articles, la documentation, les clips audio et vidéo, les éléments graphiques, les logos, les icônes, les images ou les téléchargements qu’il contient (éléments également désignés par le terme « Contenu ») sont protégés en vertu des lois relatives aux droits d’auteur, marques déposées et autres lois françaises et internationales, et appartiennent à, ou sont contrôlés par, iAdvize, des tiers ayant autorisé iAdvize à les utiliser ou à la partie désignée comme fournisseur du Contenu.<br/>
                Aucune exploitation commerciale, reproduction, représentation, utilisation, adaptation, modification, incorporation, traduction, commercialisation, partielle ou intégrale de ces éléments (y compris ceux susceptibles d’être téléchargés ou copiés) ne pourra en être faite sans l'accord préalable et écrit d’iAdvize, à l'exception de l'utilisation pour un usage privé sous réserve des dispositions différentes voire plus restrictives du droit applicable.<br/>
                Outre les droits d’auteurs, la violation de l'un de ces droits de propriété industrielle et/ou intellectuelle est un délit notamment passible de d'emprisonnement et du versement d’une amende.
                <br/><br/>
                <strong>Marques</strong><br/>
                Tous les produits constituent des noms de marques commerciales ou des marques déposées appartenant à iAdvize. Toutes les autres marques n’appartenant pas à iAdvize et présentes sur le Site sont la propriété de leurs détenteurs respectifs.
                <br/><br/>
                <strong>Respect de la Vie Privée</strong><br/>
                iAdvize prend le respect de votre vie privée au sérieux. Pour connaître la manière dont nous protégeons votre vie privée, nous vous invitons à visiter la section <Link to="/fr/rgpd">Vie privée</Link>.
                <br/><br/>
                <strong>Exonérations et Limites de responsabilité</strong><br/>
                Le site et l’ensemble de son Contenu sont fournis « en l’état » sans aucune garantie de quelque sorte, qu’elle soit implicite ou expresse, y compris, notamment, toute garantie de titre ou garantie implicite de qualité marchande, d’adéquation à un usage particulier ou de non-contrefaçon. Vous acceptez par les présentes d’utiliser le site à vos propres risques.
                iAdvize ne saurait, en aucune circonstance, être tenue responsable envers qui que ce soit de dommages spéciaux, accidentels, indirects ou consécutifs, ou de la perte de profits ou de revenus résultant du fait d’avoir utilisé les Contenus présentés ou de s’être fié à ces Contenus, même après avoir été informé à l’avance de la possibilité de tels dommages.
                Tous les efforts ont été mis en œuvre pour garantir l’exactitude des Contenus présentés. Cependant, iAdvize n’assume en aucun cas la responsabilité de l’exactitude des informations présentes dans ces Contenus. Les informations des produits sont susceptibles d’être modifiées sans avis préalable. La mention de produits ou de services ne relevant pas de iAdvize n’a pour but que d’informer et ne constitue ni une approbation ni une recommandation.
                Les exonérations et limites de responsabilité susvisées sont applicables dans la limite prévue par la législation contractuelle, juridique ou délictuelle (en ce compris, sans limite, relative à la négligence) ou de toute autre nature.
                Liens vers les sites tiers
                iAdvize ne saurait être tenue responsable du contenu, des données, des produits et informations diverses proposés à travers les sites vers lesquels des liens hypertexte sont réalisés. En particulier, iAdvize se dégage de toute responsabilité relative aux sites dont les données ne seraient pas conformes aux dispositions légales et réglementaires en vigueur.
                <br/><br/>
                <strong>Conditions Supplémentaires</strong><br/>
                Si tout ou partie des présentes Conditions Générales d’Utilisation est invalide ou inopposable dans toute juridiction, alors (i) elles devront être interprétées dans les limites autorisées par la loi de cette juridiction pour produire les mêmes effets et les exonérations de responsabilité relatives aux présentes Conditions Générales d’Utilisation resteront en vigueur et (ii) l’intégralité des présentes Conditions Générales d’Utilisation resteront en vigueur dans toutes les autres juridictions.
                Nous pouvons modifier ces Conditions Générales d’Utilisation à tout moment et à notre entière discrétion en publiant ces Conditions Générales d’Utilisation révisées sur la page Conditions Générales d’Utilisation. Ces modifications seront effectives pour vous dès leur publication, sauf indication contraire de notre part. Il est de votre responsabilité de vous informer sur ces Conditions Générales d’Utilisation révisées en consultant cette page. Si vous continuez à utiliser le présent Site après modification des présentes Conditions Générales d’Utilisation, vous êtes réputé avoir donné votre accord aux Conditions Générales d’Utilisation révisées.

                <br></br>
                <br></br>
                Les présentes Conditions Générales d’Utilisation sont régies par la législation française et doivent être interprétées conformément à cette dernière. Tout conflit entre vous et iAdvize doit être soumis à la compétence non exclusive des tribunaux de Nantes.”
            </div>
            </div>
        </section>
  
}

export default CiLegalFr



